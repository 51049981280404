.rain {
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.rain p {
  line-height: 1;
}

.rain span {
  display: block;
  width: 5px;
  height: 6px;
  font-size: 6px;
  color: #19ff1911;
  text-align: center;
  font-family: Helvetica, sans-serif;
}

.rain.large span {
  width: 16px;
  height: 16px;
  font-size: 14px;
  color: rgb(0 0 0 /0);
}
