@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .scroll-wrapper {
    @apply absolute top-0 left-0 right-0 bottom-0 overflow-y-scroll box-content;
  }

  .scroll-wrapper::-webkit-scrollbar {
    @apply w-1;
  }

  .scroll-wrapper::-webkit-scrollbar-thumb {
    @apply rounded-md bg-gray-500;
  }

  .project-scrollbar::-webkit-scrollbar {
    @apply w-1;
  }

  .project-scrollbar::-webkit-scrollbar-thumb {
    @apply rounded-md bg-gray-500;
  }

  .fetch-loading {
    @apply container mx-auto min-h-60 flex items-center justify-center text-xl font-bold text-gray-500;
  }

  .fetch-error {
    @apply container mx-auto;
  }

  .pagination {
    @apply mt-10 flex justify-center;
  }

  .pagination li {
    @apply inline px-4 py-1 border first:rounded-s-md last:rounded-e-md;
  }

  .pagination li.active {
    @apply text-orange-400 font-bold;
  }
}
